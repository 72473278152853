import "./Bloco.css";

function Bloco({ imagem, titulo, texto, id }) {
  function mudarPagina(id) {
    if(id == "carometro") {
        alert("Ainda não está pronto rs...");
    } else if(id == "gra") {
        window.location.href = "https://senai.arthurrosa.pro";
    } else {
        alert("Contate o Suporte");
    }
  }

  return (
    <>
      <div
        class="cards"
        id={id}
        onClick={() => mudarPagina(id)}
        style={{ borderColor: "white" }}
      >
        <img src={imagem} />
        <h1 style={{ color: "#333333" }}>{titulo}</h1>
        <h2>{texto}</h2>
      </div>
    </>
  );
}

export default Bloco;
