import "./App.css";
import Bloco from "./Bloco/Bloco";

function App() {
  return (
    <div className="App">
      <div className="imagem-center">
        <img alt="SENAI" src="./logo.png" />
        <div className="texto-center">
          <span>Escola SENAI "Ricardo Lerner"</span>
        </div>
      </div>

      <div class="cont-content">
        <div class="cards-group">
          <Bloco id={"carometro"} imagem={"./LogoGra.png"} titulo={"Caromêtro"} texto={"Caromêtro - Local onde são registradas ocorrências"} />
          <Bloco id={"gra"} imagem={"./LogoGra.png"} titulo={"GRA"} texto={"GRA - Gerenciamento Rápido de Ambientes"} />
        </div>
      </div>

{/*       <footer>
        <p>
          Central V0.0.3 Desenvolvido na Escola SENAI
          "Ricardo Lerner". © 2024. Responsável Técnico: Prof. Rosa
        </p>
      </footer> */}
    </div>
  );
}

export default App;
